/* You can add global styles to this file, and also import other style files */
@import './app/variables';

* {
    margin: 0;
    padding: 0;
    outline-style: none;
}
 
body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: $text-color;
    background-color: $background-color;
    overscroll-behavior: none;
}

a {
    text-decoration: none;
    color: $text-color;

    &:visited {
        color: inherit;
    }
}
 
ul {
    list-style: none;
}

.bulleted-list {
    list-style: circle;

    li {
        margin-left: 20px;
    }
}
 
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-responsive {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $breakpoint-lg) {
        flex-direction: column;
    }
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-uneven {
    align-items: flex-start;
}

.flex-uneven-end {
    align-items: flex-end;
}

.flex-right-x {
    justify-content: right;
}
 
.flex-center {
  justify-content: center;
}

.flex-center-y {
    align-items: center;
}

.flex-bottom-y {
    align-items: flex-end
}
 
.flex-wrap    { 
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
} 

.flex-nowrap {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.flex-1 { flex: 1 }
.flex-2 { flex: 2 }
.flex-3 { flex: 3 }
.flex-4 { flex: 4 }
.flex-5 { flex: 5 }
.flex-6 { flex: 6 }
.flex-7 { flex: 7 }
.flex-8 { flex: 8 }
.flex-9 { flex: 9 }
.flex-10 { flex: 10 }

.spacer-5 { width: 5px; height: 5px; }
.spacer-10 { width: 10px; height: 10px; }
.spacer-15 { width: 15px; height: 15px; }
.spacer-20 { width: 20px; height: 20px; }

.margin-bottom-5 { margin-bottom: 5px }
.margin-bottom-10 { margin-bottom: 10px }
.margin-bottom-20 { margin-bottom: 20px }
.margin-bottom-40 { margin-bottom: 40px }
.margin-bottom-60 { margin-bottom: 60px }
.margin-bottom-80 { margin-bottom: 80px }
.margin-bottom-100 { margin-bottom: 100px }

.margin-top-5 { margin-top: 5px }
.margin-top-10 { margin-top: 10px }
.margin-top-20 { margin-top: 20px }
.margin-top-40 { margin-top: 40px }
.margin-top-60 { margin-top: 60px }
.margin-top-80 { margin-top: 80px }
.margin-top-100 { margin-top: 100px }

.inline-block { display: inline-block;}

.text-left { text-align: left }
.text-right { text-align: right }
.text-center { text-align: center }

.pointer {
    cursor: pointer;
}

.text-color-green {
    color: $color-green;
}

.text-color-primary {
    color: $color-primary;
}

.text-light {
    color: $text-light;
}

.text-small {
    font-size: 0.8em;
}

.desktop-only {
    display: block;

    @media screen and (max-width: $breakpoint-md) {
        display: none;
    }
}

.wrapper {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 50px;
}

.wrapper-large {
    max-width: 1200px;
}

.wrapper-medium {
    max-width: 800px;
    margin: 25px auto;
    padding: 0px 15px;

    @media screen and (max-width: $breakpoint-md) {
        padding: 0px
    }
}

.wrapper-small {
    max-width: 600px;
    margin: 50px auto;
    padding: 0px 15px;
}

.wrapper-mini {
    max-width: 400px;
    margin: 50px auto;
    padding: 0px 15px;
}

.btn {
    padding: 9px 18px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    display: inline-block;

    &:disabled {
        cursor: not-allowed;
    }
}

.btn-small {
    padding: 5px 10px;
    font-size: 0.9em;
    display: inline-block;
}

.btn-tiny {
    padding: 2px 6px;
    font-size: 0.8em;
    display: inline-block;
}

.btn-wide {
    display: block;
    width: 100%;
}

.btn-action {
    padding: 5px 10px;
    border-radius: 20px;
    color: $color-primary;
    background-color: #fff;
    border: solid 1px $color-primary;

    &:hover {
        background-color: $color-primary;
        color: #fff;
    }
}

.btn-primary {
    color: #fff !important;
    background-color: $color-primary;
    border: solid 1px $color-primary;

    .loading-icon {
        stroke: #fff;
    }

    &:hover {
        background-color: $color-primary-dark;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $color-primary-light;

        &:hover {
            background-color: $color-primary-light;
        }
    }
}

.btn-secondary {
    color: #fff !important;
    background-color: $background-color;
    border: solid 1px $color-primary;
    stroke: $color-primary;

    .loading-icon {
        stroke: $color-primary;
    }

    &:hover {
        background-color: $background-color;
    }
}

.btn-tertiary {
    color: $color-primary !important;
    background-color: #fff;
    stroke: $color-primary;
    border: none;

    .loading-icon {
        stroke: $color-primary;
    }

    &:hover {
        background-color: #f5f5f5;
    }
}

.btn-danger {
    color: #fff !important;
    background-color: $background-dark;
    border: solid 1px $background-dark;

    .loading-icon {
        stroke: #fff;
    }

    &:hover {
        background-color: $background-dark;
    }
}

.btn-secondary-danger {
    color: $color-red !important;
    background-color: $background-color;
    border: solid 1px $color-red;
    stroke: $color-red;

    .loading-icon {
        stroke: $color-red;
    }

    &:hover {
        background-color: #ffece8 ;
    }
}

.btn-secondary-neutral {
    color: $color-grey !important;
    background-color: #fff;
    border: solid 1px $color-grey;
    stroke: $color-grey;

    .loading-icon {
        stroke: $color-grey;
    }

    &:hover {
        background-color: #f5f5f5;
    }
}

.btn-success {
    color: #fff !important;
    background-color: $color-green;
    border: solid 1px $color-green;

    .loading-icon {
        stroke: #fff;
    }

    &:hover {
        background-color: $color-green;
    }
}

.form-block,
.grid-filter {
    label {
        display: block;
        font-size: 0.9em;
    }

    input, select, textarea {
        font-size: 1.1em;
        padding: 6px;
        width: 100%;
        box-sizing: border-box;
        background-color: $background-light;
        border: solid 1px #0a0c15;
        border-radius: 4px;
        color: $color-green;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    }
}

.form-block + .form-block {
    margin-top: 20px;
}

.form-block-compact,
.grid-filter-compact {
    label {
        font-size: 0.8em;
        color: $text-light;
    }

    input, select, textarea {
        font-size: 0.9em;
        padding: 4px;
    }
}

.form-block-compact + .form-block-compact,
.form-row-compact + .form-block-compact,
.form-block-compact + .form-row-compact,
.form-row-compact + .form-row-compact {
    margin-top: 5px;
}

.form-row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .form-block,
    .form-block-compact {
        flex: 1;
    }

    .form-block + .form-block,
    .form-block-compact + .form-block-compact {
        margin-top: 0px;
        margin-left: 10px;
    }

    @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;

        .form-block + .form-block,
        .form-block-compact + .form-block-compact {
            margin-top: 10px;
            margin-left: 0px;
        }
    }
}

.auth-form {
    .hero {
        img {
            margin: 0 auto;
            width: 170px;
        }
        margin-bottom: 20px;
    
    }
    .header {
        margin-bottom: 20px;

        h1 {
            margin-bottom: 15px;
        }
    }

    .footer {
        margin: 40px 0px 10px 0px;
    
        p {
            padding: 10px 0px;
        }
    }
}

.outline {
    display: inline-block;
    padding: 3px;
    border: solid 1px $color-green;
    background-color: #5bf29624;
    color: #fff;
    border-radius: 4px;
    line-height: 1rem;
}

.green-underline {
    border-bottom: solid 1px $color-green
}

.red-underline {
    border-bottom: solid 1px $color-red
}

.highlight {
    background-color: $color-primary;
    color: $background-color;
    border-radius: 3px;
}

.lesson-complete {
    .highlight {
        background-color: $color-green;
        color: $background-color;
        border-radius: 3px;
    }
}

.info-page {
    .highlight {
        background-color: $color-green;
        color: $background-color;
        border-radius: 3px;
    }
}

.highlight-green {
    background-color: $color-green;
    color: $background-color;
    border-radius: 3px;
}

.heromoji {
    font-size: 100px;
    margin-bottom: 10px;
}
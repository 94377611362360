$color-primary: #ff0066;
$color-primary-dark: #d10959;
$color-primary-light: #fe7aaf;
$color-primary-ultralight: #ffebf3;
$color-primary-transparent-80: #ff006637;

$color-grey: #848484;
$color-grey-light: #F4F5F8;
$color-yellow: #5bf296;
$color-green: #5bf296;
$color-green-ultralight: #dbffe9;
$color-red: #e44848;
$color-red-ultralight: #ffeeea;
$color-blue: #2685f2;
$color-blue-ultralight: #e1efff;

$text-light: #6b6d7d;
$text-color: #bfbfbf;

$background-color: #1D2021;
$background-light: #303136;
$background-dark: #010202;

$border-color: #3e3e3e;
$border-light: #d3d3d3;

$nav-height: 50px;

$breakpoint-lg: 900px;
$breakpoint-md: 700px;
$breakpoint-sm: 500px;